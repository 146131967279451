<template>
	<v-row dense>
		<v-col cols="12">
			<v-row dense class="text-md-body-1 text-md-h6">
				<v-col cols="12" sm="4" md="3">
					<v-card class="d-flex align-center py-4" outlined :height="height">
						<v-icon color="green" size="40" class="pa-3">
							far fa-money-bill-alt
						</v-icon>
						<div class="font-weight-400 ml-1 green--text">
							<div>Wholesale value of your order (net)</div>
							<div class="d-flex align-center">
								{{ clientPrice / 100 | euro }}
							</div>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" sm="4" md="3">
					<v-card class="d-flex align-center py-4" outlined :height="height">
						<v-icon color="black" size="40" class="pa-3">
							fas fa-euro-sign
						</v-icon>
						<div class=" font-weight-400 ml-1">
							<div>Retail value of your order (23% tax incl.)</div>
							<div class="d-flex align-center">
								{{ retailPrice / 100 | euro }}
							</div>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" sm="4" md="3">
					<v-card class="d-flex align-center py-4" outlined :height="height">
						<v-progress-circular
							:rotate="270"
							:size="60"
							:width="3"
							:value="missingPercentage"
							color="black"
							class="ml-2 mr-1 caption"
						>
							<div class="text-no-wrap" v-if="dlFlex">
								-
							</div>
							<div class="text-no-wrap" v-else-if="missing >=0">
								{{ missing / 100 | euro_small }}
							</div>
							<div class="text-no-wrap" v-else>
								Max DL
							</div>
						</v-progress-circular>
						<div class="font-weight-400 ml-1 ">
							<div>Your Discount Level:&nbsp;{{ activeDl }}</div>
							<div v-if="missing >=0 && !dlFlex">Next Discount Level:&nbsp;{{ activeDl + 1 }}</div>
						</div>
					</v-card>
				</v-col>
				<v-col cols="12" sm="12" md="3" class="">
					<v-card class="pa-1 d-flex align-center justify-center" outlined
							:height="$vuetify.breakpoint.smAndDown ? 'auto' : height">
						<dialog-summary ref="dialogSummary">
							<template v-slot:activator_section>
								<v-btn
									color="green"
									dark class="ma-1"
									@click="basket"
									id="order_form-summary_1"
								>
									BASKET
								</v-btn>
							</template>
						</dialog-summary>
						<v-btn
							color="error"
							id="order_form-order_now_1"
							dark
							class="ma-1 white--text"
							@click="orderNow"
							:loading="loadingBtnOrderNow"
						>
							ORDER NOW
						</v-btn>
					</v-card>
				</v-col>
			</v-row>

			<block-categories-summary/>
		</v-col>
	</v-row>
</template>
<script>

import DialogSummary from "@/views/Dashboard/Dialog/DialogSummary";
import BlockCategoriesSummary from "@/views/Dashboard/Blocks/BlockCategoriesSummary";

export default {
	name: 'BlockSummary',
	components: {BlockCategoriesSummary, DialogSummary},
	computed: {

		activeDl() {
			return this.$store.getters['DASHBOARD_STORE/ACTIVE_DL'];
		},
		retailPrice() {
			return this.$store.getters['DASHBOARD_STORE/RETAIL_PRICE'];
		},
		clientPrice() {
			return this.$store.getters['DASHBOARD_STORE/CLIENT_PRICE'];
		},
		dls() {
			return this.$store.getters['DASHBOARD_STORE/DLS'];
		},

		dlFlex() {
			return this.$store.getters['AUTH_STORE/DL_FLEX'];
		},

		missing() {
			let missing = this.dls[this.activeDl] - this.retailPrice;
			return missing >= 0 ? missing : -1;
		},
		missingPercentage() {
			if (this.dlFlex) {
				return 0
			}

			let p = 0;
			let earlierDl = this.dls[this.activeDl - 1] ? this.dls[this.activeDl - 1] : 0;

			p = (this.retailPrice - earlierDl) * 100 /
				(this.dls[this.activeDl] - earlierDl);

			return p < 100 ? p : 100;
		},


		height() {
			if (this.$vuetify.breakpoint.xsOnly) {
				return 70;
			} else if (this.$vuetify.breakpoint.mdAndDown) {
				return 85;
			}

			return 120;
		},
	},
	data: () => ({
		loadingBtnOrderNow: false,
	}),
	methods: {
		basket() {
			this.$refs.dialogSummary.showDialog()
		},

		orderNow() {
			this.loadingBtnOrderNow = true;
			this.$gtag.event(
				'Custom',
				{
					'klik': 'order_form-order_now',
				}
			)
			this.$store.dispatch('DASHBOARD_STORE/updateBasket')
				.then(() => {
					this.loadingBtnOrderNow = false;
					this.$router.push({name: 'BasketIndex'})
				})
				.catch(err => {
					console.error(err)
					this.loadingBtnOrderNow = false;
				});
		},
	},
};
</script>
