<template>
	<div
		class="d-flex align-center justify-center"
	>
		<v-btn icon small @click="addQuantity(-1)">
			<v-icon color="red" size="12">
				fas fa-minus
			</v-icon>
		</v-btn>

		<v-sheet :width="width" color="transparent">
			<v-text-field
				label=""
				outlined
				class="table--product-quantity"
				dense
				hide-details
				v-model="quantity"
				@change="checkQuantity()"
			/>
		</v-sheet>

		<v-btn icon small @click="addQuantity(1)">
			<v-icon color="green" size="12">
				fas fa-plus
			</v-icon>
		</v-btn>
	</div>
</template>
<script>


export default {
	name: 'BlockProductQuantity',
	components: {},
	computed: {},
	props: {
		item: {
			type: Object,
			required: true,
		},
		width: {
			type: Number,
			required: false,
			default: 60
		},
	},
	data: () => ({
		quantity: 0,
	}),
	methods: {
		addQuantity(value) {
			this.quantity += value * 1;
			this.checkQuantity();
		},
		checkQuantity(created = false) {
			this.quantity = parseInt(this.quantity);

			if (!this.quantity || this.quantity < 1) {
				this.quantity = 0;
			}

			if (this.quantity > 9999) {
				this.quantity = 9999;
			}

			if (!created) {
				this.$store.dispatch('DASHBOARD_STORE/updateProductQuantity', {
					id: this.item.id,
					value: this.quantity,
					created: created,
				})
			}

		},
	},
	created() {
		if (this.item.quantity !== null) {
			this.quantity = this.item.quantity;
		}

		this.checkQuantity(true);
	}
};
</script>
