<template>
	<v-card
		outlined
		class="dashboard--content pa-4 mt-2 hidden-xs-only"
		ref="strollTo"
		:key="key"
	>

		<div class="mb-2 text-right">
			<v-icon v-if="loadingUpdateBasket" class="mr-1">fas fa-spinner fa-pulse</v-icon>
			<v-btn outlined @click="clearBasket()">CLEAR BASKET</v-btn>
		</div>

		<v-data-table
			:headers="headers"
			:items="products"
			class="text-body-1"
			:search="search"
			:items-per-page="-1"
			hide-default-footer
			mobile-breakpoint="0"
		>
			<template v-slot:item="{item, headers}">
				<tr
					v-if="item.type==='category'"
				>
					<td
						:colspan="headers.length"
						:class="`id-${item.id}`"
					>
						<div class="text-h6" v-html="item.name"/>
					</td>

				<tr
					v-if="item.type==='product'"
				>
					<td>
						<div
							@click="$refs.dialogProductImage.showDialog(item)"
						>
							<v-img
								v-if="item.img"
								:src="item.img"
								max-width="45"
								max-height="45"
								class="ma-2 cursor--pointer"
								:alt="item.name"
							/>
						</div>
					</td>
					<td>
						<v-sheet class="font-weight-500 black--text" min-width="100" color="transparent" v-if="item.status.slug ==='coming_soon'">
							<v-chip
								style="height: 20px"
								small
								color="orange"
								label
								dark
								class="px-1 py-0 mr-1"
							>
								COMING SOON
							</v-chip>
							<a :href="item.link" v-if="item.link" target="_blank">
								{{ item.name }}
							</a>
							<span v-else>{{ item.name }}</span>
						</v-sheet>
						<v-sheet class="font-weight-500 black--text" min-width="100" color="transparent" v-else>
							<v-chip
								style="height: 20px"
								small
								v-for="(a, i) in  item.accents "
								:color="a.color.name"
								:key="i"
								label
								dark
								class="px-1 py-0 mr-1"
							>
								{{a.name}}
							</v-chip>
							<a :href="item.link" v-if="item.link" target="_blank">
								{{ item.name }}
							</a>
							<span v-else>{{ item.name }}</span>
						</v-sheet>
					</td>
					<td>
						<div class="font-weight-500 text-no-wrap text-right" v-if="item.status.slug !=='coming_soon'">
							{{ item.retailPrice.price / 100 | euro }}
						</div>
					</td>
					<td>
						<ul class="table--product-prices" v-if="item.status.slug !=='coming_soon'">
							<li
								v-for="(p, index) in item.prices"
								:key="index"
								:class="{
									active: index === activeDl
								}"
								class="text-no-wrap"
							>
								{{
									index === activeDl
										? 'DL#' + index
										: ''
								}}

								<span class="price">
									{{ p.price / 100 | euro }}
								</span>
							</li>
						</ul>
					</td>
					<td>
						<block-product-quantity
							v-if="item.status.slug !=='coming_soon'"
							:item="item" :key="`${item.id}-${item.total}`"
						/>
					</td>
					<td class="text-right" >
						<div class="font-weight-700 text-no-wrap text-right" v-if="item.status.slug !=='coming_soon'">
							{{ item.total / 100 | euro }}
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>
		<dialog-product-image ref="dialogProductImage"/>
	</v-card>
</template>
<script>
import BlockProductQuantity from "@/views/Dashboard/Blocks/BlockProductQuantity";
import DialogProductImage from "@/views/Dashboard/Dialog/DialogProductImage";


export default {
	name: 'BlockContent',
	components: {DialogProductImage, BlockProductQuantity},
	computed: {
		loadingUpdateBasket() {
			return this.$store.getters['DASHBOARD_STORE/LOADING_UPDATE_BASKET'];
		},
		products() {
			return this.$store.getters['DASHBOARD_STORE/PRODUCTS'];
		},

		activeDl() {
			return this.$store.getters['DASHBOARD_STORE/ACTIVE_DL'];
		},

		goToId() {
			return this.$store.getters['DASHBOARD_STORE/GO_TO'];
		},
	},
	data: () => ({
		search: '',
		key: 0,

		headers: [
			{
				text: 'Image', value: 'img', sortable: false, width: 90, align: 'center'
			},
			{
				text: 'Product', value: 'name', sortable: false, align: 'left'
			},
			{
				text: 'Retail Price (23% tax incl.)', value: 'retail', sortable: false, width: 130, align: 'right'
			},
			{
				text: 'Your Price (net)', value: 'your', sortable: false, width: 150, align: 'right',
			},
			{
				text: 'Quantity', value: 'quantity', sortable: false, width: 140, align: 'center',
			},
			{
				text: 'Total', value: 'total', sortable: false, width: 120, align: 'right',
			},
		]
	}),
	methods: {
		goToElement() {
			const el = this.$refs.strollTo;
			if (el) {
				const el = this.$el.getElementsByClassName('id-' + this.goToId)[0];
				if (el) {
					el.scrollIntoView({behavior: 'smooth'});
				}
			}
		},
		clearBasket() {
			this.$swal({
				icon: 'warning',
				title: "Are you sure you want to clear the shopping cart?",
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'Cancel',
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('DASHBOARD_STORE/clearBasket');
				}
			});
		},
	},
	watch: {
		goToId() {
			if (this.goToId) {
				this.goToElement();
			}
		},
		products() {
			this.key++;
		},
	},
	mounted() {
	}
};
</script>
