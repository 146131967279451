<template>
	<v-dialog
		v-model="dialog"
		width="700"
		content-class="dialog--default dialog--summary"
		v-if="product"
	>
		<template v-slot:activator="{ on }">
			<slot name="activator_section" v-on="on"/>
		</template>

		<v-card color="white">
			<v-card-title class="">
				<div>Product {{ product.name }}</div>
				<v-spacer/>
				<div class="" @click="dialog = false">
					<v-btn
						icon
						text
					>
						<v-icon size="18">mdi-close</v-icon>
					</v-btn>
				</div>
			</v-card-title>

			<v-card-text class="mt-2 mb-4 pb-0 text-center">
				<v-img
					max-width="500"
					class="ma-auto"
					:src="product.img"
				/>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>
<script>


export default {
	name: 'DialogProductImage',
	components: {},
	computed: {},
	props: [],
	methods: {
		showDialog(item) {
			this.product = item;
			this.dialog = true;
		},

	},
	data: () => ({
		dialog: false,
		product: null,

	}),
	watch: {
		dialog() {
			if (this.dialog) {
				this.$gtag.event(
					'Custom',
					{
						'klik': 'order_form-image',
					}
				)
			}
		}
	},
	created() {
	}
};
</script>
