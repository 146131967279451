<template>
	<div class="dashboard" v-if="!loading">

		<block-summary/>

		<block-content v-if="!$vuetify.breakpoint.xsOnly && height > 600"/>

		<block-content-mobile v-else/>

		<dialog-promo-info/>

	</div>
	<v-card
		v-else
		class="text-center font-weight-300 text-h3 py-8"
	>
		Loading
		<v-icon color="black" size="36">fas fa-spinner fa-pulse</v-icon>
	</v-card>
</template>
<script>
import BlockSummary from "@/views/Dashboard/Blocks/BlockSummary";
import BlockContent from "@/views/Dashboard/Blocks/BlockContent";
import BlockContentMobile from "@/views/Dashboard/Blocks/BlockContentMobile";
import DialogPromoInfo from "@/components/Dialogs/DialogPromoInfo";


export default {
	name: 'DashboardIndex',
	components: {DialogPromoInfo, BlockContentMobile, BlockContent, BlockSummary},
	computed: {
		loading() {
			return this.$store.getters['DASHBOARD_STORE/LOADING'];
		},
	},
	methods: {
	},
	data: () => ({
		height: window.innerHeight,
	}),
	watch: {
	},
	created() {
		this.$store.dispatch('DASHBOARD_STORE/getProducts');
		this.$store.dispatch('DASHBOARD_STORE/getCategories');
	}
};
</script>
