<template>
	<v-dialog
		transition="dialog-top-transition"
		max-width="700"
		v-model="dialog"
		v-if="promotionOn && promo"
		persistent
	>
		<v-form
			ref="form"
			lazy-validation
		>
			<v-card>
				<v-card-title class="text-h5 text-md-h4 font-weight-400 mb-2 d-flex align-center" v-if="promo.title">
					<div v-html="promo.title" style="word-break: break-word;"/>
					<v-spacer/>
					<div class="" @click="close()">
						<v-btn
							icon
							text
						>
							<v-icon size="18">mdi-close</v-icon>
						</v-btn>
					</div>
				</v-card-title>

				<v-card-text class="black--text text--lighten-3">
					<div v-html="promo.text" v-if="promo.text"/>

					<v-img
						v-if="promo.image"
						max-width="100%"
						:src="`/img/${promo.image}`"
					/>
				</v-card-text>


				<v-card-actions class="justify-end pb-4 mt-2 px-4">
					<v-btn
						color="blue"
						class="px-8 white--text"
						@click="close"
					>
						Close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>

<script>

export default {
	name: 'DialogPromoInfo',
	components: {},
	computed: {},
	data() {
		return {
			dialog: false,

			promotionOn: false,
			promo: null,
		}
	},
	methods: {
		getPromotion() {
			this.$store.dispatch('DASHBOARD_STORE/getPromotion')
				.then(resp => {
					if (
						resp.data.data
						&& localStorage.getItem('showPromotion') !== resp.data.data.id.toString()
					) {
						this.promo = resp.data.data;
						this.promotionOn = true;
						this.dialog = true;
					}
				})
				.catch(err => {
					console.error(err)
					this.promotionOn = false;
					this.dialog = false
				});
		},

		close() {
			this.dialog = false;
			localStorage.setItem('showPromotion', this.promo.id);
		}
	},
	created() {
		this.getPromotion();
	},
};
</script>
