<template>
	<v-card
		outlined class="dashboard--content-mobile pa-4 mt-2"
		ref="strollTo"
	>

		<div class="mb-2 text-right">
			<v-icon v-if="loadingUpdateBasket" class="mr-1">fas fa-spinner fa-pulse</v-icon>
			<v-btn outlined @click="clearBasket()" class="order_form-clear_basket">
				CLEAR BASKET
			</v-btn>
		</div>

		<v-divider/>

		<div v-for="(item, index) in products" :key="index">
			<div v-if="item.type==='category'">
				<div
					:colspan="headers.length"
					:class="`id-${item.id}`"
					class="my-1"
				>
					<div class="text-h6 " v-html="item.name"/>
				</div>
			</div>
			<div v-else class="">
				<div class="d-flex mt-2">
					<div class="font-weight-500 mb-2 black--text" v-if="item.status.slug ==='coming_soon'">
						<v-chip
							style="height: 20px"
							small
							color="orange"
							label
							dark
							class="px-1 py-0 mr-1"
						>
							COMING SOON
						</v-chip>
						{{ item.name }}
					</div>
					<div class="font-weight-500 black--text" v-else>
						<v-chip
							style="height: 20px"
							small
							v-for="(a, i) in  item.accents "
							:color="a.color.name"
							:key="i"
							label
							dark
							class="px-1 py-0"
						>
							{{ a.name }}
						</v-chip>
						{{ item.name }}
					</div>
					<v-spacer/>
					<div class="text-no-wrap" v-if="item.status.slug !=='coming_soon'">{{ item.total / 100 | euro }}</div>
				</div>
				<div class="d-flex align-center mt-2 mb-2" v-if="item.status.slug !=='coming_soon'">
					<div class="" >
							<span
								v-for="(p, index) in item.prices"
								:key="index"
								:class="{
									active: index === activeDl
								}"
								class="text-no-wrap text-caption mr-2 float-left"
							>
								{{
									index === activeDl
										? 'DL#' + index
										: ''
								}}

								<span class="price text-no-wrap">
									{{ p.price / 100 | euro_small }}
								</span>
							</span>
					</div>

					<v-spacer/>

					<div>
						<block-product-quantity :item="item" :width="50" :key="`${item.id}-${item.total}`"/>
					</div>
				</div>
			</div>
			<v-divider/>
		</div>
	</v-card>
</template>
<script>
import BlockProductQuantity from "@/views/Dashboard/Blocks/BlockProductQuantity";


export default {
	name: 'BlockContentMobile',
	components: {BlockProductQuantity},
	computed: {
		loadingUpdateBasket() {
			return this.$store.getters['DASHBOARD_STORE/LOADING_UPDATE_BASKET'];
		},
		products() {
			return this.$store.getters['DASHBOARD_STORE/PRODUCTS'];
		},

		activeDl() {
			return this.$store.getters['DASHBOARD_STORE/ACTIVE_DL'];
		},

		goToId() {
			return this.$store.getters['DASHBOARD_STORE/GO_TO'];
		},
	},
	data: () => ({
		search: '',

		headers: [
			{
				text: 'Image', value: 'img', sortable: false, width: 90, align: 'center'
			},
			{
				text: 'Product', value: 'name', sortable: false, align: 'left',
			},
			{
				text: 'Retail Price (23% tax incl.)', value: 'retail', sortable: false, width: 130, align: 'right'
			},
			{
				text: 'Your Price (net)', value: 'your', sortable: false, width: 150, align: 'right',
			},
			{
				text: 'Quantity', value: 'quantity', sortable: false, width: 140, align: 'center',
			},
			{
				text: 'Total', value: 'total', sortable: false, width: 120, align: 'right',
			},
		]
	}),
	methods: {
		goToElement() {
			const el = this.$refs.strollTo;
			if (el) {
				const el = this.$el.getElementsByClassName('id-' + this.goToId)[0];
				if (el) {
					el.scrollIntoView({behavior: 'smooth'});
				}
			}
		},
		clearBasket() {
			this.$swal({
				icon: 'warning',
				title: "Are you sure you want to clear the shopping cart?",
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				cancelButtonText: 'Cancel',
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('DASHBOARD_STORE/clearBasket');
				}
			});
		},
	},
	watch: {
		goToId() {
			if (this.goToId) {
				this.goToElement();
			}
		},
	},
	created() {
	}
};
</script>
