<template>
	<dialog-summary ref="dialogSummary">
		<template v-slot:activator_section>
			<v-card
				outlined
				class="mt-2 pa-2"
				@click="$refs.dialogSummary.showDialog()"
			>
				<v-row dense class="text-sm-body-1 font-weight-400">
					<v-col cols="6" sm="6" md="4" lg="2" v-for="i in items" :key="i.id" class="text-center">
						{{ i.name }}: {{ getCount(i.id) }}
					</v-col>
				</v-row>
			</v-card>
		</template>
	</dialog-summary>
</template>
<script>

import DialogSummary from "@/views/Dashboard/Dialog/DialogSummary";
export default {
	name: 'BlockCategoriesSummary',
	components: {DialogSummary},
	computed: {
		products() {
			return this.$store.getters['DASHBOARD_STORE/PRODUCTS_WITH_QUANTITY'];
		},
		items() {
			return this.$store.getters['DASHBOARD_STORE/CATEGORIES']
				.filter(i => this.categoriesInSummary.includes(i.id));
		},
		categoriesInSummary() {
			return this.$store.getters['DASHBOARD_STORE/CATEGORIES_IN_SUMMARY'];
		},
	},
	methods: {
		getCount(id) {
			return this._.sumBy(this.products.filter(i => i.firstCategoryId === id), 'quantity')

		}
	},
	data: () => ({}),
	mounted() {
	}
};
</script>
